<template>
  <div class="">
    <el-card class="box-card condition-box">
      <el-select
        v-model="pageInfo.companyJobId"
        placeholder="全部职位"
        @change="selectStatus"
        style="width: 130px; margin-right: 50px"
      >
        <el-option
          v-for="item2 in positionoptions"
          :key="item2.value"
          :label="item2.label"
          :value="item2.value"
        >
        </el-option>
      </el-select>
    </el-card>
    <el-card class="box-card tiaojian">
      <el-form
        :model="pageInfo"
        ref="ruleForm"
        label-position="top"
        label-width="130px"
        class="demo-ruleForm from-box"
      >
        <el-form-item label="学历">
          <el-select
            v-model="pageInfo.educationTypes"
            multiple
            placeholder="请选择学历"
            style="width: 394px"
          >
            <el-option
              v-for="item in educationOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="工作年限" style="margin-left: 30px">
          <el-select
            v-model="pageInfo.expLow"
            placeholder="请选择工作年限"
            style="width: 182px; margin-right: 30px"
          >
            <el-option
              v-for="item in expLowOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select
            v-model="pageInfo.expHigh"
            placeholder="请选择工作年限"
            style="width: 182px"
          >
            <el-option
              v-for="item in expHighOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <br />
        <el-form-item label="期望薪资" prop="gzmax">
          <el-select
            v-model="pageInfo.salaryLow"
            placeholder="不限"
            style="width: 182px; margin-right: 30px"
          >
            <el-option
              v-for="item in gzLowOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select
            v-model="pageInfo.salaryHigh"
            placeholder="不限"
            style="width: 182px"
          >
            <el-option
              v-for="item in gzHighOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="项目起始时间"
          prop="startTime"
          class="xmzhouqi"
          style="margin-left: 30px"
        >
          <el-date-picker
            type="date"
            placeholder="起"
            v-model="pageInfo.start"
            style="width: 182px; margin-right: 30px"
          ></el-date-picker>
          <el-date-picker
            type="date"
            placeholder="止"
            v-model="pageInfo.end"
            style="width: 182px"
          ></el-date-picker>
        </el-form-item>
        <br />
        <!-- <div class="" style="width: 400px;"></div> -->
        <el-form-item>
          <el-button class="chongzhi" @click="resetForm('ruleForm')"
            >重置</el-button
          >
          <el-button class="chongzhi" @click="search">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 列表 -->
    <el-card class="box-card listbox" style="margin-top: 15px">
      <!-- 无数据 -->
      <div class="nodata" v-if="userList != null && userList.length == 0">
        <img src="../../assets/images/person/p-nodata.png" />
        <div class="font16">当前无数据</div>
      </div>
      <div class="">
        <el-card
          class="li-main"
          v-for="(itemes, index3) in userList"
          :key="index3"
        >
          <div class="list-content" @click="todetail(itemes)">
            <div class="person-info">
              <div class="p-picurl">
                <img
                  :src="'https://freemen.work/user/file/view/' + itemes.avatar"
                />
                <span
                  class="bofang font20"
                  v-if="itemes.resUrl"
                  @click.stop="videoshow(itemes)"
                >
                  <!-- <i class="el-icon-video-play"></i> -->
                  <img src="../../assets/images/common/bofangicon.png" />
                </span>
              </div>
              <div class="p-news">
                <div class="pn-name">
                  <div class="namemain">
                    <span class="n-name font22">{{ itemes.displayName }}</span>
                    <img src="../../assets/images/person/boy.png" />
                    <span class="font14">Free</span>
                  </div>
                  <div class="score font14">
                    <span>{{ itemes.score }}</span>
                  </div>
                </div>
                <div class="beizhu font14">
                  <div class="">
                    {{ itemes.expYear }}年
                    {{
                      itemes.educationType ? (" | " + itemes.educationType.itemText) : " "
                    }}
                    | {{
                      new Date().getYear() -
                      new Date(itemes.birthday).getYear() -
                      (new Date().getMonth() >
                      new Date(itemes.birthday).getMonth()
                        ? 0
                        : 1)
                    }}岁 | {{ itemes.jobLevelNames }}
                  </div>
                  <!-- {{itemes.educationList.length ? itemes.educationList[itemes.educationList.length-1].educationType.itemText : '/'}}|{{new Date().getYear() - new Date(itemes.birthday).getYear()}}岁 -->
                  <div class="">
                    期望工作时间：{{ datatime(itemes.startDate) }} —
                    {{
                      datatime(itemes.endDate) ? datatime(itemes.endDate) : "/"
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="transitionbox" v-if="itemes.userWorkExpList.length > 0">
              <!-- 3个 -->
              <template v-if="itemes.userWorkExpList.length < 4">
                <div
                  class="t-one font14"
                  v-for="(item, index) in itemes.userWorkExpList"
                  :key="index"
                >
                  <img src="../../assets/images/person/gangwei.png" />
                  <span @click.stop="getMoreWork(itemes)">
                    {{ item.companyName }} ·
                    {{ item.jobName }}
                  </span>
                </div>
              </template>
              <!-- 大于3 -->
              <template v-if="itemes.userWorkExpList.length > 3">
                <div class="t-one font14">
                  <img src="../../assets/images/person/gangwei.png" />
                  <span @click.stop="getMoreWork(itemes)">
                    {{ itemes.userWorkExpList[0].companyName }} ·
                    {{ itemes.userWorkExpList[0].jobName }}
                  </span>
                  <i
                    :class="
                      itemes.moreShow
                        ? 'el-icon-arrow-up'
                        : 'el-icon-arrow-down'
                    "
                    @click.stop="getMoreWork(itemes)"
                  ></i>
                </div>
              </template>
              <!-- 大于3 -->
              <el-collapse-transition v-if="itemes.userWorkExpList.length > 3">
                <div v-show="itemes.moreShow">
                  <div
                    class="t-one font14"
                    v-show="index > 0"
                    v-for="(item, index) in itemes.userWorkExpList"
                    :key="index"
                  >
                    <img src="../../assets/images/person/gangwei.png" />
                    <span>{{ item.companyName }} · {{ item.jobName }}</span>
                  </div>
                </div>
              </el-collapse-transition>
            </div>
            <div class="label-info">
              <div class="li-left font14">
                <span
                  v-show="index < 5"
                  v-for="(item, index) in itemes.skillIds"
                  :key="index"
                  >{{ item.itemText }}</span
                >
              </div>
              <div class="li-right">
                <!-- <el-button class="yy" type="primary" @click="noPass(itemes)">不合适</el-button> -->
                <el-button class="gt" type="primary" @click.stop="Chat(itemes)"
                  >沟通</el-button
                >
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <div class="nextpage" @click="getMoreList" v-if="moreBtn">
        点击加载更多
      </div>
    </el-card>

    <!-- 沟通消耗聊天卡弹窗 -->
    <el-dialog
      :visible.sync="cardDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="updown_box">
        <div class="ub-img">
          <img src="../../assets/images/person/yongcard.png" />
        </div>
        <div class="ub-text font22">发起聊天将消耗一次聊天卡，确认继续？</div>
        <div class="btnbox">
          <el-button
            class="qx"
            type="primary"
            @click="cardDialogVisible = false"
            >取消</el-button
          >
          <el-button class="qr" type="primary" @click="chatCommit"
            >确认</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!-- 聊天卡额度不足弹窗 -->
    <el-dialog
      :visible.sync="nocardDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="updown_box">
        <div class="ub-img">
          <img src="../../assets/images/person/qianbao.png" />
        </div>
        <div class="ub-text font22">当前聊天卡额度不足，是否购买？</div>
        <div class="btnbox">
          <el-button
            class="qx"
            type="primary"
            @click="nocardDialogVisible = false"
            >取消</el-button
          >
          <el-button class="qr" type="primary" @click="buyCard"
            >去购买</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { cityDataes } from "../../common/addAddress.js";
import { options } from "../../common/options.js";
export default {
  data() {
    return {
      pageInfo: {
        pageNo: 1,
        pageSize: 10,
        companyJobId: "",
        city: "",
        educationTypes: [],
        expHigh: "",
        expLow: "",
        salaryHigh: "",
        salaryLow: "",
        start: "",
        end: "",
      },
      moreBtn: false,
      positionoptions: [],
      educationOptions: JSON.parse(sessionStorage.getItem("educationType")),
      gzLowOptions: options.gzLowOptions,
      gzHighOptions: options.gzHighOptions,
      expHighOptions: options.expHighOptions,
      expLowOptions: options.expLowOptions,
      userList: null,
      cardDialogVisible: false, //消耗聊天卡弹窗
      nocardDialogVisible: false, //聊天卡额度不足弹窗
      addressoptions: [],
      selectCity: [],
      chatConfig: {
        companyJobId: "",
        userId: "",
        userJobId: "",
      },
    };
  },
  activated() {
    this.getJobList();
    this.getList();
    this.chishiaddress();
  },
  computed: {},
  methods: {
    buyCard() {
      this.nocardDialogVisible = false;
      this.dialogVisible = false;
      window.location.href = window.location.origin + "/#/propsmall";
    },
    getJobList() {
      //获取职位列表
      this.$api.joblist("get").then((res) => {
        this.positionoptions = [];
        res.data.map((item) => {
          if (item.status == 1) {
            let obj = {
              label: item.jobName,
              value: item.id,
            };
            this.positionoptions.push(obj);
          }
        });
      });
    },
    getList() {
      let config = JSON.parse(JSON.stringify(this.pageInfo));
      if (this.pageInfo.start) {
        let startTime = new Date(config.start);
        let y = startTime.getFullYear();
        let m = startTime.getMonth() + 1;
        let d = startTime.getDate();
        config.start = y + "-" + m + "-" + d;
      }
      if (this.pageInfo.end) {
        let endTime = new Date(config.end);
        let y = endTime.getFullYear();
        let m = endTime.getMonth() + 1;
        let d = endTime.getDate();
        config.end = y + "-" + m + "-" + d;
      }
      if (this.pageInfo.salaryLow == "") {
        config.salaryLow = "";
      } else {
        // config.salaryLow = Number(this.pageInfo.salaryLow) * 1000;
        config.salaryLow = Number(this.pageInfo.salaryLow);
      }
      if (this.pageInfo.salaryHigh == "") {
        config.salaryHigh = "";
      } else {
        // config.salaryHigh = Number(this.pageInfo.salaryHigh) * 1000;
        config.salaryHigh = Number(this.pageInfo.salaryHigh);
      }
      config.educationTypes = this.pageInfo.educationTypes.join(",");
      this.$api.getPostList("get", config).then((res) => {
        if (!res.data) {
          res.data = [];
        }
        if (this.pageInfo.companyJobId) {
          let arr = [];
          res.data.map((item) => {
            if (item.companyJobId == this.pageInfo.companyJobId) {
              arr.push(item);
            }
          });
          // this.userList = this.userList.concat(arr);
          if (config.pageNo == 1) {
            this.userList = arr;
          } else {
            this.userList = this.userList.concat(arr);
          }
        } else {
          // this.userList = this.userList.concat(res.data);
          if (config.pageNo == 1) {
            this.userList = res.data;
          } else {
            this.userList = this.userList.concat(res.data);
          }
        }
        if (res.data.length < 10) {
          this.moreBtn = false;
        } else {
          this.moreBtn = true;
        }
        this.userList.map((item) => {
          item.moreShow = false;
        });
      });
    },
    search() {
      this.pageInfo.pageNo = 1;
      this.userList = [];
      this.getList();
    },
    getMoreList() {
      //下一页
      this.pageInfo.pageNo++;
      this.getList();
    },
    selectStatus() {
      //选择职位
      this.pageInfo.pageNo = 1;
      this.userList = [];
      this.getList();
    },
    getMoreWork(data) {
      //展示所有工作经历
      data.moreShow = !data.moreShow;
      this.$forceUpdate();
    },
    noPass(data) {
      this.$api.postNopass("post", { id: data.id }).then(() => {
        this.pageInfo.pageNo = 1;
        this.userList = [];
        this.getList();
        this.$message({
          message: "操作成功",
          type: "success",
        });
      });
    },
    chishiaddress() {
      this.addressoptions = cityDataes.cityData;
    },
    handleChange(value) {
      cityDataes.cityData.map((item) => {
        if (item.value == value[0]) {
          item.children.map((sub) => {
            if (sub.value == value[1]) {
              this.pageInfo.city = sub.label;
            }
          });
        }
      });
    },
    videoshow(data) {
      let config = {
        show: true,
        data: data,
      };
      this.$videoDetail.show(config);
    },
    todetail(data) {
      this.$resumeDetail.show({
        show: true,
        type: "toudi",
        companyJobId: data.companyJobId,
        userJobId: data.userJobId,
        positionoptions: this.positionoptions,
        callback: () => {
          let tempQuery = {
            active: 1,
            companyJobId: data.companyJobId,
            companyJobName: data.companyJobName,
            userJobId: data.userJobId,
            userId: data.userId,
          }
          this.$router.push({ path: "chatmain", query: tempQuery});
        },
      });
    },
    resetForm(formName) {
      var self = this;
      self.$refs[formName].resetFields();
      self.pageInfo = {
        companyJobId: "",
        city: "",
        educationTypes: [],
        expHigh: "",
        expLow: "",
        salaryHigh: "",
        salaryLow: "",
        start: "",
        end: "",
      };
      this.getList();
    },
    datatime(value) {
      var data = new Date(value);
      var month =
        data.getMonth() < 9 ? "0" + (data.getMonth() + 1) : data.getMonth() + 1;
      var date = data.getDate() <= 9 ? "0" + data.getDate() : data.getDate();
      return data.getFullYear() + "-" + month + "-" + date;
    },
    Chat(data) {
      this.$api
        .checkChatCard("get", {
          companyJobId: data.companyJobId,
          userJobId: data.userJobId,
        })
        .then((res) => {
          if (res.data > 0) {
            let tempQuery = {
              active: 1,
              companyJobId: data.companyJobId,
              companyJobName: data.companyJobName,
              userJobId: data.userJobId,
              userId: data.userId,
            }
            this.$router.push({ path: "chatmain", query: tempQuery});
          } else {
            this.cardDialogVisible = true;
            this.chatConfig.companyJobId = data.companyJobId;
            this.chatConfig.userId = data.userId;
            this.chatConfig.userJobId = data.userJobId;
          }
        });
    },
    chatCommit() {
      this.$api.useChatCard("post", this.chatConfig).then((res) => {
        this.cardDialogVisible = false;
        if (res.code == "10901") {
          this.nocardDialogVisible = true;
        } else {
          let tempQuery = {
            active: 1,
            companyJobId: this.chatConfig.companyJobId,
            companyJobName: this.chatConfig.companyJobName,
            userJobId: this.chatConfig.userJobId,
            userId: this.chatConfig.userId,
          }
          this.$router.push({ path: "chatmain", query: tempQuery});
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.condition-box {
  /deep/ .el-input__inner {
    border-radius: 0px;
  }
}
.tiaojian {
  margin-top: 15px;
  .from-box {
    width: 850px;
    .el-form-item {
      margin-bottom: 10px;
    }
    /deep/ .el-input__inner {
      border-radius: 0px;
    }
    /deep/ .el-button {
      border-radius: 0px;
    }
    // /deep/.el-form-item:nth-child(even){
    // 	margin-left: 30px;
    // }
    /deep/.el-form--label-top,
    /deep/.el-form-item__label {
      padding: 0;
      color: #111111;
      font-size: 14px;
    }
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .chongzhi {
      width: 200px;
      background-color: #00bcff;
      border: none;
      color: #ffffff;
    }
  }
}
.listbox {
  .nextpage {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999999;
    cursor: pointer;
    margin-top: 15px;
  }
  .nodata {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #111111;
    padding: 37px 0 20px 0;
    img {
      width: 256px;
      height: 215px;
      margin-bottom: 20px;
    }
  }
  .li-main:hover {
    transform: scale(1.02);
    background-color: rgba(0, 0, 0, 0.001);
  }
  .li-main {
    margin-bottom: 15px;
    .list-content {
      display: flex;
      flex: 1;
      flex-direction: column;
      .person-info {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #dddddd;
        padding-bottom: 20px;
        .p-picurl {
          margin-right: 20px;
          position: relative;
          img {
            width: 50px;
            height: 50px;
            // filter: blur(2px);
          }
          .bofang:hover {
            cursor: pointer;
          }
          .bofang {
            position: absolute;
            top: -3px;
            right: -6px;
            // border-radius: 50px;
            // color: #3F3F3F;
            img {
              width: 22px;
              height: 22px;
            }
          }
        }
        .p-news {
          display: flex;
          flex: 1;
          flex-direction: column;
          line-height: 1.6;
          .pn-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .namemain {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              .n-name {
                // filter: blur(2px);
              }
              img {
                width: 24px;
                height: 24px;
                margin: 0 18px 0 10px;
              }
              span:last-child {
                background-color: #e9f9ff;
                color: #00bcff;
                padding: 2px 10px;
              }
            }
            .score {
              background-color: #00bcff;
              border-radius: 8px 8px 8px 0;
              color: #ffffff;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .beizhu {
            color: #666666;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            div {
              margin-right: 48px;
            }
          }
        }
      }
      .transitionbox {
        .t-one:hover {
          cursor: pointer;
        }
        .t-one {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: #666666;
          margin-top: 20px;
          img {
            width: 22px;
            height: 20px;
          }
          span {
            margin: 0 30px 0 10px;
          }
        }
      }
      .label-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        .li-left {
          span {
            background-color: #eeeeee;
            padding: 4px 8px;
            margin-right: 10px;
            color: #666666;
          }
        }
        .li-right {
          display: flex;
          align-items: center;
          /deep/ .el-button {
            border-radius: 0px;
            height: 40px;
            margin-left: 30px;
            border: none;
          }
          .gt {
            background-color: #00bcff;
          }
          .yy {
            background-color: #ffa544;
          }
        }
      }
    }
  }
}
.updown_box {
  line-height: 3;
  padding: 40px 0;
  .ub-img {
    text-align: center;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .ub-text {
    text-align: center;
    color: #111111;
  }
  .btnbox {
    text-align: center;
    /deep/ .el-button {
      border-radius: 0px;
      width: 200px;
      margin-top: 50px;
      background: #00bcff;
      border: none;
    }
    .qx {
      background-color: #ffffff;
      color: #00bcff;
      border: 1px solid #00bcff;
      margin-right: 10px;
    }
  }
}
.updown_box {
  padding: 20px;
  text-align: center;
}
.updown_box .ub-img img {
  width: 50px;
}
.ub-text {
  margin: 20px 0 50px 0;
}
.updown_box .qx {
  border: 1px solid #00bcff;
  background-color: #ffffff;
  color: #00bcff;
  width: 150px;
  margin-right: 20px;
}
.updown_box .qr {
  background-color: #00bcff;
  border: 1px solid #00bcff;
  width: 150px;
  margin-left: 20px;
}
</style>
